@tailwind base;
@tailwind components;
@tailwind utilities;

/* CLERK CSS */

.cl-profileSectionContent.cl-profileSectionContent__activeDevices {
  height: 300px;
  overflow-y: scroll;
}
.cl-card {
  box-shadow: none;
}

a:hover {
  color: #ff9500; /* Altere para a cor desejada */
}
.banner-bg {
  background-image: radial-gradient(circle 248px at center, #ff9500 0%, #ff9500 47%, #FFE6C2 100%);
}

.half-underline::before {
    content: "";
    display: inline-block;
    width: 70%; /* metade da largura do elemento pai */
    border-bottom: 6px solid #ff9500; /* linha sublinhada */
    vertical-align: text-bottom;
    margin-right: -70%; /* ajuste para alinhar a linha sublinhada */
    margin-bottom: -16px; /* aumenta a distância da linha sublinhada em relação ao texto */
}

/* Custom styles to hide default number input arrows */
@layer components {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}